<script lang="ts" setup>
import NavigationDesktop from "@/components/NavigationDesktop.vue";
import { cn } from "@/lib/utils";
import { useRoute } from "vue-router";

const route = useRoute();
</script>

<template>
    <div class="relative h-screen w-full md:flex">
        <NavigationDesktop />
        <main
            id="main"
            :class="
                cn('relative grow max-md:h-full md:overflow-y-auto', {
                    'px-10 py-12': route.name !== 'communication',
                })
            ">
            <slot />
        </main>
    </div>
</template>
